body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pre-loading{
  /* position: absolute; */
  display: flex;
  flex-direction: row;     /* make main axis horizontal (default setting) */
  justify-content: center; /* center items horizontally, in this case */
  align-items: center;     /* center items vertically, in this case */
  height: 250px;
}

.grayed-out {
  filter: grayscale(100%); /* Define o nível de cinza (100% para escala completa de cinza) */
}


.myButton2  {background-image: linear-gradient(to right, #000000 0%, #434343  51%, #000000  100%)}
.myButton2 {
  
   padding: 10px;
   text-align: center;
   text-transform: uppercase;
   transition: 0.5s;
   background-size: 200% auto;
   color: white;            
   box-shadow: 0 0 20px #eee;
   border-radius: 10px;
   display: block;
 }

 .myButton2 :hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }






.bg-logo{

	width:40%;
	margin-bottom: -30px;
}

.logo-tl{
	width: 100%;
}

.logo-tl-tx{
	color:#000000;
	font-size: 12px;
}

.card-txt{
	padding: 10px;
}



.row .col {
      padding: 0 !important;
}

.ptxt{
	margin-left: 20px!important;
	margin-bottom: 0;
}
.card-txt{
	margin-top: -20px!important;;
}


.card-txt{
	margin-top: -50px;
}

.card-video {
	margin: 0;
}

.bt-cp{
	margin-left: -10px;
}

.titName{
	
	font-size: 16px;
}

.time {
    position: relative;
    left: 50px;
    top: -23px;
}

.btn-floating.btn-large {
    width: 36px;
    height: 36px;
    padding: 0;
}

.card-action{
	border-bottom: 1px solid#f6f6f6;
	margin-bottom: 10px;
}

.min-icon {
    /* width: 50px; */
    font-size: 15px;
    margin-right: 3px;
    position: relative;
    top: 2px;
}

.btn-floating.btn-large {
    width: 36px;
    height: 36px;
    padding: 0;
}

.titName {
    position: relative;
    left: 8px;
    top: 4px;
}

.linkBt {
  top: -5px;
  position: relative;
  left: 24px;
}
.rodape {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 10px 0;
}